var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailContent" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "80px"
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.dataFormSubmit()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "type", $$v)
                    },
                    expression: "dataForm.type"
                  }
                },
                _vm._l(_vm.typeList, function(type, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: index } },
                    [_vm._v(_vm._s(type) + "\n\t\t\t\t")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.typeList[_vm.dataForm.type] + "名称",
                prop: "name"
              }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.typeList[_vm.dataForm.type] + "名称"
                },
                model: {
                  value: _vm.dataForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "name", $$v)
                  },
                  expression: "dataForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "navIndex", prop: "navIndex" } },
            [
              _c("el-input", {
                attrs: { placeholder: "navIndex" },
                model: {
                  value: _vm.dataForm.navIndex,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "navIndex", $$v)
                  },
                  expression: "dataForm.navIndex"
                }
              })
            ],
            1
          ),
          _vm.dataForm.type !== 3
            ? _c(
                "el-form-item",
                { attrs: { label: "上级菜单", prop: "parentName" } },
                [
                  _c(
                    "el-popover",
                    {
                      ref: "menuListPopover",
                      attrs: { placement: "bottom-start", trigger: "click" }
                    },
                    [
                      _c("el-tree", {
                        ref: "menuListTree",
                        staticClass: "tree-wrapper",
                        attrs: {
                          data: _vm.menuList,
                          props: _vm.menuListTreeProps,
                          "node-key": "uuid",
                          "default-expand-all": true,
                          "highlight-current": true,
                          "expand-on-click-node": false
                        },
                        on: {
                          "current-change": _vm.menuListTreeCurrentChangeHandle
                        }
                      }),
                      _c("el-input", {
                        staticClass: "menu-list__input",
                        attrs: {
                          slot: "reference",
                          readonly: true,
                          placeholder: "点击选择上级菜单"
                        },
                        slot: "reference",
                        model: {
                          value: _vm.dataForm.parentName,
                          callback: function($$v) {
                            _vm.$set(_vm.dataForm, "parentName", $$v)
                          },
                          expression: "dataForm.parentName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.dataForm.type === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "菜单路由", prop: "url" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "菜单路由" },
                    model: {
                      value: _vm.dataForm.url,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "url", $$v)
                      },
                      expression: "dataForm.url"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.dataForm.type !== 0
            ? _c(
                "el-form-item",
                { attrs: { label: "授权标识", prop: "perms" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "例:user:list,user:create" },
                    model: {
                      value: _vm.dataForm.perms,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "perms", $$v)
                      },
                      expression: "dataForm.perms"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.dataForm.type == 0 || _vm.dataForm.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "排序号", prop: "weight" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      label: "排序号"
                    },
                    model: {
                      value: _vm.dataForm.weight,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "weight", $$v)
                      },
                      expression: "dataForm.weight"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.dataForm.type == 0 || _vm.dataForm.type == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "菜单图标", prop: "icon" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-popover",
                            {
                              ref: "iconListPopover",
                              attrs: {
                                placement: "bottom-start",
                                trigger: "click",
                                "popper-class": "mod-menu__icon-popover"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mod-menu__icon-list" },
                                _vm._l(_vm.iconList, function(item, index) {
                                  return _c(
                                    "el-button",
                                    {
                                      key: index,
                                      class: {
                                        "is-active": item === _vm.dataForm.icon
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.iconActiveHandle(item)
                                        }
                                      }
                                    },
                                    [_c("i", { class: item })]
                                  )
                                }),
                                1
                              )
                            ]
                          ),
                          _c("el-input", {
                            directives: [
                              {
                                name: "popover",
                                rawName: "v-popover:iconListPopover",
                                arg: "iconListPopover"
                              }
                            ],
                            staticClass: "icon-list__input",
                            attrs: { placeholder: "菜单图标名称" },
                            model: {
                              value: _vm.dataForm.icon,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "icon", $$v)
                              },
                              expression: "dataForm.icon"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { staticClass: "buttons" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.flag },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit()
                    }
                  }
                },
                [_vm._v("确认提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }