<template>
	<div class="detailContent">
		<el-form :model="dataForm" :rules="dataRule" ref="formData" @keyup.enter.native="dataFormSubmit()"
			label-width="80px">
			<el-form-item label="类型" prop="type">
				<el-radio-group v-model="dataForm.type">
					<el-radio v-for="(type, index) in typeList" :label="index" :key="index">{{ type }}
					</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item :label="typeList[dataForm.type] + '名称'" prop="name">
				<el-input v-model="dataForm.name" :placeholder="typeList[dataForm.type] + '名称'"></el-input>
			</el-form-item>
			<el-form-item label="navIndex" prop="navIndex">
				<el-input v-model="dataForm.navIndex" placeholder="navIndex"></el-input>
			</el-form-item>
			<el-form-item label="上级菜单" prop="parentName" v-if="dataForm.type !== 3">
				<el-popover ref="menuListPopover" placement="bottom-start" trigger="click">
					<el-tree :data="menuList" :props="menuListTreeProps" node-key="uuid" ref="menuListTree"
						@current-change="menuListTreeCurrentChangeHandle" :default-expand-all="true"
						:highlight-current="true" :expand-on-click-node="false" class="tree-wrapper">
					</el-tree>
					<el-input v-model="dataForm.parentName" slot="reference" :readonly="true" placeholder="点击选择上级菜单"
						class="menu-list__input"></el-input>
				</el-popover>
			</el-form-item>
			<el-form-item v-if="dataForm.type === 1" label="菜单路由" prop="url">
				<el-input v-model="dataForm.url" placeholder="菜单路由"></el-input>
			</el-form-item>
			<el-form-item v-if="dataForm.type !== 0" label="授权标识" prop="perms">
				<el-input v-model="dataForm.perms" placeholder="例:user:list,user:create"></el-input>
			</el-form-item>
			<el-form-item v-if="dataForm.type == 0 || dataForm.type == 1" label="排序号" prop="weight">
				<el-input-number v-model="dataForm.weight" controls-position="right" :min="0" label="排序号">
				</el-input-number>
			</el-form-item>
			<el-form-item v-if="dataForm.type == 0 || dataForm.type == 1" label="菜单图标" prop="icon">
				<el-row>
					<el-col :span="22">
						<el-popover ref="iconListPopover" placement="bottom-start" trigger="click" popper-class="mod-menu__icon-popover">
							<div class="mod-menu__icon-list">
								<el-button v-for="(item, index) in iconList" :key="index"
									@click="iconActiveHandle(item)" :class="{
											'is-active': item === dataForm.icon
										}">
									<i :class="item"></i>
								</el-button>
							</div>
						</el-popover>
						<el-input v-model="dataForm.icon" v-popover:iconListPopover placeholder="菜单图标名称"
							class="icon-list__input"></el-input>
					</el-col>
				</el-row>
			</el-form-item>

			<el-form-item class="buttons">
				<el-button type="primary" @click="dataFormSubmit()" :disabled="flag">确认提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
import { treeDataTranslate } from '@/utils';

export default {
	name: 'menuOption',
	data () {
		var validateUrl = (rule, value, callback) => {
			if (this.dataForm.type === '1' && !/\S/.test(value)) {
				callback(new Error('菜单URL不能为空'));
			} else {
				callback();
			}
		};
		return {
			typeList: ['目录', '菜单', '按钮', '接口权限'],
			dataForm: {
				id: 0,
				type: 1,
				name: '',
				parentId: 0,
				parentName: '',
				url: '',
				perms: '',
				weight: 0,
				icon: '',
				navIndex: '' // 注意：这个值，与 vue router 的meta信息中的 navIndex是相对应的.
			},
			dataRule: {
				name: [
					{
						required: true,
						message: '菜单名称不能为空',
						trigger: 'blur'
					}
				],
				navIndex: [
					{
						required: true,
						message: 'navIndex不能为空',
						trigger: 'blur'
					}
				],
				parentName: [
					{
						required: true,
						message: '上级菜单不能为空',
						trigger: 'change'
					}
				],
				url: [{ validator: validateUrl, trigger: 'blur' }]
			},
			menuList: [],
			menuListTreeProps: {
				label: 'name',
				children: 'children'
			},
			flag: false,
			iconList: [
				'el-icon-eleme',
				'el-icon-delete-solid',
				'el-icon-s-tools',
				'el-icon-setting',
				'el-icon-user-solid',
				'el-icon-s-goods',
				'el-icon-folder-add',
				'el-icon-help',
				'el-icon-s-ticket',
				'el-icon-picture-outline',
				'el-icon-s-home',
				'el-icon-s-grid',
				'el-icon-share',
				'el-icon-c-scale-to-original',
				'el-icon-paperclip',
				'el-icon-document-add',
				'el-icon-notebook-1',
				'el-icon-notebook-2',
				'el-icon-shopping-bag-1',
				'el-icon-shopping-bag-2',
				'el-icon-present',
				'el-icon-chat-dot-round',
				'el-icon-microphone',
				'el-icon-map-location',
				'el-icon-location'
			]
		};
	},
	beforeCreate () {
		if (this.$route.query.id) {
			this.$route.meta.navName = '修改菜单';
		} else {
			this.$route.meta.navName = '新增菜单';
		}
	},
	created () {
		this.menuSelect();
		if (this.$route.query.id) {
			this.getDetail(this.$route.query.id);
		}
	},
	methods: {
		back () {
			this.$router.go(-1);
		},
		dataFormSubmit () {
			this.$refs.formData.validate((valid) => {
				if (valid) {
					this.$root.request('menuAdd', {
						...this.dataForm
					}).then((data) => {
						if (data) {
							this.$root.elMsg('操作成功', 'success', () => {
								if (this.$route.query.id) {
									this.back();
								} else {
									this.$refs.formData.resetFields();
									this.menuSelect();
								}
							}, 1000);
						}
					}).catch(e => {
						this.$root.elMsg(e.msg, 'error');
					});
				} else return false;
			});
		},
		// 图标选中
		iconActiveHandle (iconName) {
			this.dataForm.icon = iconName;
		},
		// 菜单树选中
		menuListTreeCurrentChangeHandle (data, node) {
			this.dataForm.parentId = data.id;
			this.dataForm.parentName = data.name;
		},
		// 获取上级菜单
		menuSelect () {
			this.$root.request('menuSelect').then((data) => {
				if (data) {
					this.menuList = treeDataTranslate(data, 'id');
				}
			});
		},
		// 获取上级菜单
		getDetail (id) {
			this.$root.request('menuDetail', { id }).then((data) => {
				if (data) {
					this.dataForm = {
						id: this.$route.query.id,
						type: data.type,
						name: data.name,
						navIndex: data.navIndex,
						parentId: data.parentId,
						parentName: data.parentName,
						url: data.url,
						perms: data.perms,
						weight: data.weight,
						icon: data.icon
					};
				}
			});
		}
	}
};
</script>
<style lang="less">
	.mod-menu__icon-popover {
		max-width: 300px !important;
	}
</style>
<style lang="less" scoped>
/deep/ .el-input {
	width: auto !important;
}
.mod-menu__icon-list {
	padding: 15px !important;
	max-height: 300px !important;
}
.mod-menu {
	.menu-list__input,
	.icon-list__input {
		> .el-input__inner {
			cursor: pointer;
		}
	}
	&__icon-list {
		max-height: 180px;
		padding: 0;
		margin: -8px 0 0 -8px;
		> .el-button {
			padding: 8px;
			margin: 8px 0 0 8px;
			> span {
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				font-size: 18px;
			}
		}
	}
	.icon-list__tips {
		font-size: 18px;
		text-align: center;
		color: #e6a23c;
		cursor: pointer;
	}
}
.tree-wrapper {
	height: 350px;
	overflow-y: auto;
}
</style>